// AuthContext.js

import React, { createContext, useContext, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [token,setToken]=useState(null);
  const navigate=useNavigate();
  const location = useLocation();

  const login = async (credentials) => {
    try {

      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
          
      };

      const response = await ApiTemplate(
        "post",
        "/api/v1/login",
        JSON.stringify({
          email: credentials.email,
          password: credentials.password,
        }),
        headers,

      );
      if (response && response["success"] === true) {
        setToken(response["data"]["token"]);
        localStorage.setItem("token", JSON.stringify(response["data"]["token"]));
        localStorage.setItem("user", JSON.stringify(response["data"]["user"]));
        localStorage.setItem('favorite', JSON.stringify(response["data"]["favorite"]));
        setIsLoggedIn(true);
        setUserProfile(response["data"]["user"]);
        return response;
      } else {
        return response;
      }

    } catch (error) {
      console.log("error.message message: ", error.message)
      setError(error.message)
      return null;
    }
  };


  const signup = async (credentials) => {
    try {

      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),

      };

      const response = await ApiTemplate(
        "post",
        "/api/v1/signup",
        JSON.stringify({

          "auth_type": "email",

          name: credentials.name,
          phone: credentials.phone,
          address: credentials.address,
          email: credentials.email,
          password: credentials.password,
        }),
        headers,

      );
      if (response && response["success"] === true) {
        localStorage.setItem("token", JSON.stringify(response["data"]["token"]));
        localStorage.setItem("user", JSON.stringify(response["data"]["user"]));

        setIsLoggedIn(true);
        setUserProfile(response["data"]["user"]);
        return response;
      } else {
        return response;

      }

    } catch (error) {
      console.log("error.message message: ", error.message)
      return null;
    }
  };


  const signupwithgooogle = async (credentials) => {
    try {

      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),

      };

      const response = await ApiTemplate(
        "post",
        "/api/v1/signup",
        credentials,
        headers,

      );
      if (response && response["success"] === true) {
        localStorage.setItem("token", JSON.stringify(response["data"]["token"]));
        localStorage.setItem("user", JSON.stringify(response["data"]["user"]));

        setIsLoggedIn(true);
        setUserProfile(response["data"]["user"]);
        return response;
      } else {
        return response;

      }

    } catch (error) {
      console.log("error.message message: ", error.message)
      return null;
    }
  };



  const logout = async () => {
    // Implement the logic to log out the user, e.g., clearing tokens or session data
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem('favorite');
    setIsLoggedIn(false);
    setUserProfile(null);
  };

  const isUserLoggedIn = () => {
    return isLoggedIn;
  };

  const checkUser = async () => {
    try {
      const token = await JSON.parse( localStorage.getItem("token"));
      if (token !== null) {
        const headers = {
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": document.head
            .querySelector('meta[name="csrf-token"]')
            ?.getAttribute("content"),
          "Authorization": "Bearer " + token,
        };

        const response = await ApiTemplate(
          "get",
          "/api/v1/verify/token",
          {},
          headers,
        );

        if (response && response["success"] === true) {
          localStorage.setItem("user", JSON.stringify(response["data"]));
          setIsLoggedIn(true);
          setUserProfile(response["data"]);

        } else {
          setIsLoggedIn(false);
          setUserProfile(null);
        }


      } else {
        setIsLoggedIn(false);
        setUserProfile(null);
      }
    } catch (error) {
      console.error("Error while checking user status:", error);
    }
  };

  const updateUserProfile = async (credentials) => {
    const token = JSON.parse(localStorage.getItem('token'))
    try {

      const headers = {
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
          Authorization: `Bearer ${token}`

      };

      const response = await ApiTemplate(
        "post",
        "/api/v1/userUpdate",
        credentials,
        headers,
      );
      if (response && response["success"] === true) {
        const redirectTo = location.state?.from;
        navigate(redirectTo);
        return response;
      } else {
        return response;

      }

    } catch (error) {
      console.log("error.message message: ", error.message)
      return null;
    }
  };

  const passwordReset = async (credentials) => {
    // const token = JSON.parse(localStorage.getItem('token'))
    try {

      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
      };

      const response = await ApiTemplate(
        "post",
        "/api/v1/password/email",
        credentials,
        headers,

      );
      if (response && response["success"] === true) {
        return response;
      } else {
        return response;

      }

    } catch (error) {
      console.log("error.message message: ", error.message)
      return null;
    }
  };

  const confirmationCode = async (credentials) => {
    // const token = JSON.parse(localStorage.getItem('token'))
    try {

      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
      };

      const response = await ApiTemplate(
        "post",
        "/api/v1/password/code/check",
        credentials,
        headers,

      );
      if (response && response["success"] === true) {
        return response;
      } else {
        return response;
      }

    } catch (error) {
      console.log("error.message message: ", error.message)
      return null;
    }
  };

  const confirmNewPassword = async (credentials) => {
    // const token = JSON.parse(localStorage.getItem('token'))
    try {

      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
      };

      const response = await ApiTemplate(
        "post",
        "/api/v1/password/reset",
        credentials,
        headers,

      );
      if (response && response["success"] === true) {
        return response;
      } else {
        return response;

      }

    } catch (error) {
      console.log("error.message message: ", error.message)
      return null;
    }
  };


  const getUserProfile = () => {
    return userProfile;
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        signup,
        logout,
        isUserLoggedIn,
        checkUser,
        getUserProfile,
        userProfile,
        error,
        setError,
        isLoggedIn,
        signupwithgooogle,
        token,
        updateUserProfile,
        passwordReset,
        confirmationCode,
        confirmNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const  useAuthContext = () => {
  const authContextValue = useContext(AuthContext);
  return authContextValue;
};
