import React, { useEffect } from "react";
import { useEventContext } from "../../contexts/EventContextProvider";
import { Link } from "react-router-dom";
import fillHeartIcon from "../../images/flowbite_heart-gry.svg";
import heartIcon from "../../images/flowbite_heart-fill.svg";
import { FaCalendar } from "react-icons/fa";
import { baseUrl } from "../../apis/base_url";
import { ColorRing } from "react-loader-spinner";

const FavoriteEvents = () => {
  const { getAllFavorite, allFavoriteEvents, addRemoveEvent } =
    useEventContext();

  useEffect(() => {
    getAllFavorite();
  }, []);
  return (
    <>
      {!addRemoveEvent ? (
        <div className="md:w-[75%] sm:w-[80%] mx-auto">
          <div className="mt-5 flex flex-col gap-5 ">
            {allFavoriteEvents["data"]?.length > 0 ? (
              allFavoriteEvents["data"]?.map((item, index) => {
                return <RetreatPlanCard key={index} data={item} />;
              })
            ) : (
              <p className="font-bold text-3xl text-center shadow-md rounded-md p-3">
                No favorite event contains in your favorite list.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full my-20 text-center">
          <ColorRing
            visible={true}
            height="80"
            width="80"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperClass="blocks-wrapper"
            colors={["black"]}
          />
        </div>
      )}
    </>
  );
};

export default FavoriteEvents;

function RetreatPlanCard({ data }) {
  const { removeFromFavorite } = useEventContext();
  return (
    <div
      className="eventsCard shadow-lg flex gap-4 p-4 rounded-md text-[#505D68] md:flex-row flex-col  cursor-pointer "
    >
      {/* image */}
      <div className="md:w-[25%] w-full relative">
        <Link to={`/retreat-plans/${data.event_id}`}>
          <img
            loading="lazy"
            className="w-full object-cover h-[13.4rem]  object-center rounded-md"
            // src='https://www.google.com/imgres?q=profile%20avatar&imgurl=https%3A%2F%2Fimg.freepik.com%2Ffree-vector%2Fflat-style-woman-avatar_90220-2944.jpg&imgrefurl=https%3A%2F%2Fwww.freepik.com%2Fvectors%2Ffemale-profile-avatar&docid=poh6enuhg4Al0M&tbnid=UqM4Ji7f3f6YpM&vet=12ahUKEwiCpfih1diJAxUu3gIHHSHjJ4AQM3oECG8QAA..i&w=626&h=626&hcb=2&itg=1&ved=2ahUKEwiCpfih1diJAxUu3gIHHSHjJ4AQM3oECG8QAA'
            src={
              baseUrl +
              "/" +
              data?.event?.card_image?.path +
              "/" +
              data?.event?.card_image?.name
            }
            alt="place"
          />
        </Link>
        <div className="absolute top-2 right-2">
          <img
            src={heartIcon}
            onClick={() => removeFromFavorite(data.event_id)}
          />
          {/* <img src={fillHeartIcon}/> */}

          {/* <MdFavoriteBorder className="text-3xl border-white"/> */}
        </div>
      </div>
      {/* text */}
      <Link
        to={`/retreat-plans/${data.event_id}`}
        className="md:w-[75%] w-full flex flex-col justify-between"
      >
        {/* <div className="md:w-[75%] w-full flex flex-col justify-between"> */}
        <div className="flex items-center justify-between w-full">
          <h4 className="font-normal text-base">{data.event.location}</h4>
          <p>
            No. of Persons:{" "}
            <span className="font-extrabold">
              {data.event.total_group_members}
            </span>
            /group
          </p>
        </div>
        <h3 className="text-xl font-bold md:w-[70%] w-full text-black">
          <Link to={`/retreat-plans/${data.id}`}>{data.event.title}</Link>
        </h3>
        <p>
          <span className="font-extrabold">Duration: </span>{" "}
          {data.event.duration} Days,
          <span className="font-extrabold"> Description:</span>{" "}
          {data.event.description}
        </p>
        <div className="flex items-center justify-between w-full">
          <p className="flex gap-3">
            <span>
              <FaCalendar size={20} />
            </span>
            May-Aug
          </p>
          <h3 className="text-2xl font-bold text-black">{data.event.price}$</h3>
        </div>
        {/* </div> */}
      </Link>
    </div>
  );
}
