import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/yoga_logo.svg";

function Footer() {
  const navItems = [
    { text: "Home", link: "/" },
    { text: "Retreats", link: "/retreat-plans" },
    { text: "About us", link: "about-us" },
    { text: "Blogs", link: "/blogs" },
    { text: "Privacy Policy", link: "/privacy-policy" },
    { text: "Contact Us", link: "/contact-us" }
  ];

  return (
    <div className="flex flex-col items-center justify-center w-full gap-3 mt-24 mb-10">
      <Link to="/">
        <img loading="lazy" className="w-[3.5rem] h-[3.5rem] " src={logo} alt="place" />
      </Link>

      {/* Logo */}
      <Link to="/">
        <div className="flex items-center flex-shrink-0 text-black ">
          <h1 className="text-2xl font-black min-w-max md:text-4xl">
            Yoga Retreats
          </h1>
        </div>
      </Link>

      <ul className="flex flex-col items-center justify-center list-none lg:flex-grow lg:justify-center lg:flex-row ">
        {navItems.map((item, index) => (
          <li className="relative nav-item" key={index}>
            <Link
              to={item.link}
              className={`px-4 py-2 flex items-center justify-center text-[#676767] font-normal text-base`}
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Footer;
