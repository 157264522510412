import React, { useState } from 'react'
import ReviewModal from './review-modal'
import { useSubscriptionContext } from '../contexts/SubscriptionContextProvider';
import { useNavigate } from 'react-router-dom';
import { infoToast } from '../utils/utilityFunctions';


const AddReview = () => {
    const [isOpen, setIsOpen] = useState(false);
      const { plansList, getAllPlans } = useSubscriptionContext();
      const navigate =  useNavigate();
    
      const hasActivePlan = plansList?.plans?.some((plan) => plan.is_active === 1);
    const handleClose = () => setIsOpen(false);
    const handleSubmit = (data) => {
      console.log("Review Submitted:", data);
      setIsOpen(false); // Close modal after submitting
    };

    const handleReviewModal = () =>{
      if(hasActivePlan){
        setIsOpen(!isOpen);
      }else{
        navigate('/see-plans');
        infoToast("Buy plan first");
      }
    }
  return (
    <div className='z-30'>
       <button  onClick={handleReviewModal} className="relative overflow-hidden text-md font-medium bg-slate-300 text-black mt-3 px-4 py-1 rounded-lg tracking-wide hover:bg-customGreen hover:text-white">
         <span className='text-yellow-500 text-xl'>★</span> Leave Review  <span className='text-yellow-500 text-xl'>★</span>
        </button>

         {/* Review Form */}
      {
        isOpen && 
       <ReviewModal isOpen={isOpen}
        onClose={handleClose}
        onSubmit={handleSubmit}
       />
      }
    </div>
  )
}

export default AddReview