import React from "react";

function Banner({ image, title }) {
  return (
    <div>
      <div className="relative grid grid-cols-3 gap-4  w-[70%] m-auto">
        <div className="md:col-span-2 col-span-3">
          <img
            className="h-[20rem] w-full bg-no-repeat bg-center object-cover rounded-lg"
            src={image}
            loading="lazy"
            alt="Terms and conditions"
          />
        </div>
        <div className="w-[100%] top-0 left-0 absolute h-[17rem] bg-opacity-30 flex ">
          <div className="inset-0 flex items-center justify-center mx-auto">
            <h1 className="text-4xl font-bold ml-44 text-center text-white">
              {title}
            </h1>
          </div>
        </div>
        <div className="md:col-span-1 col-span-3">
          <video controls className="h-[20rem] w-full object-cover rounded-lg">
            <source src="/vedios/yogamaster.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}

export default Banner;
