import React, {createContext, useContext, useEffect, useState} from 'react';
import { jsonHeader } from '../apis/header';
import ApiTemplate from '../apis/api_template';
import { successToast } from '../utils/utilityFunctions';

const SubscriptionContext =  createContext();

export const SubscriptionContextProvider = ({children}) =>{
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [plansList, setPlansList] = useState(null);
  const [isPlansGet, setIsPlansGet] = useState(null);
  const [isCancelPlan, setIsCancelPlan] = useState(false);

  const getAllPlans = async () =>{
    setIsPlansGet(true);
    try{
      const tok = localStorage.getItem('token')
      const headers= jsonHeader(JSON.parse(tok));
      const response = await ApiTemplate('get', '/api/v1/subscriptionplans', {} , headers);
      if(response && response["success"] ===  true){
        setPlansList(response?.data);
        setIsPlansGet(false);
      }
    }catch(error){
      console.error("Somthing goes wrong! :", error.message);
      setIsPlansGet(false);
    }
  }


  const payChoosedPlan = async (payload) =>{
    // setIsPlansGet(true);
    try{
      const headers= jsonHeader(token);
      const response = await ApiTemplate('post', '/api/v1/pay', payload , headers);
      if(response){
        getAllPlans();
      }
    }catch(error){
      console.error("Somthing goes wrong! :", error.message);
      // setIsPlansGet(false);
    }
  }

  const cancelPlan = async () =>{
    // alert("Hello")
    setIsCancelPlan(true);
    try {
      const tok = localStorage.getItem('token')
      const headers = jsonHeader(JSON.parse(tok));
      const response = await ApiTemplate('post', '/api/v1/cancel-subscription', {}, headers);
      if(response){
        successToast("Plan cancel successfully")
        getAllPlans();
      }
    } catch (error) {
      console.error("Error :", error)
    }finally{
      setIsCancelPlan(false)
    }
  }

  useEffect(()=>{
    setToken(JSON.parse(localStorage.getItem('token')))
  },[])

  return (
    <SubscriptionContext.Provider value={{
      plansList,
      isPlansGet,
      getAllPlans,
      payChoosedPlan,
      cancelPlan,
      isCancelPlan
      }}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export const useSubscriptionContext = () => {
  const subscriptionContextValue = useContext(SubscriptionContext);
  return subscriptionContextValue;
};