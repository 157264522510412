import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useEventContext } from '../../contexts/EventContextProvider';
import { baseUrl } from '../../apis/base_url';
import { IoMdArrowRoundBack } from "react-icons/io";

const Gallery = () => {
  const {id} = useParams();
  const { getEventById, event } = useEventContext();
  const navigate = useNavigate();


  useEffect(()=>{
    getEventById(id);
  },[])


  return (
    <div className="lg:w-[80%] w-full lg:p-0 px-4 mt-16 mx-auto">
    <h3 className='text-2xl mb-2 font-bold flex gap-2'><IoMdArrowRoundBack size={23} className='mt-1 cursor-pointer' onClick={()=>navigate(-1)}/> Photos {event && event["extra_images"].length}</h3> 
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {event &&
        event["extra_images"].map((image, index) => (
          <img
            key={index}
            loading="lazy"
            className="w-full object-cover h-[24rem] object-center rounded-md"
            src={baseUrl + "/" + image.path + "/" + image.name}
            alt={`extra-image-${index}`}
          />
        ))}
    </div>
  </div>
  
  )
}

export default Gallery