import React from "react";
import SearchableDropdown from "../../components/searchable_dropdown";
import backgroundImg from "../../images/home_banner_bg.webp"

function Banner() {

    const bannerStyle = {
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroudPosition: "100% center",
        backgroundColor: "#0000004D",
      };
  return (
    <div className="min-h-[85.6vh] z-10 bg-center relative text-white  flex flex-col justify-center items-center bg-cover bg-no-repeat p-4"
    style={bannerStyle}>
        
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30 "></div>
    
      <h1 className=" z-10 md:text-5xl  text-3xl font-bold text-center mb-3">Elevate Your Soul,<br></br> Find Serenity.</h1>
      <p className=" z-10 md:w-[70%] w-full text-center mb-4 md:text-base text-sm">
        Welcome to a place where tranquility meets transformation. Our yoga
        retreats are designed to take you on a journey of self-discovery and
        renewal. Join us amidst breathtaking natural settings, immerse yourself
        in the practice of yoga, and reconnect with your inner peace. Explore
        our retreats and embark on a path to serenity, one asana at a time.
      </p>
      <div className="md:w-[60%] w-full">
        <SearchableDropdown />
      </div>

      {/* <button onClick={openModal} className="open-modal-button">Open Modal from Parent</button>
      <Accommodation isOpen={isModalOpen} onClose={closeModal}/> */}
    </div>
  );
}

export default Banner;
