import React, { useState } from "react";
import { useReviewsContext } from "../contexts/ReviewContextProvider";
import { errorToast } from "../utils/utilityFunctions";
import ClickAwayListener from "react-click-away-listener";

const ReviewModal = ({ isOpen, onClose, onSubmit }) => {
  const [star, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const {addReview, isAddReview } = useReviewsContext();

  const handleSubmit = async () => {
    if (star === 0 || message.trim() === "") {
      errorToast("Please fill all fields and select a star.");
      return;
    }
    await addReview({ star, message });
    setRating(0);
    setMessage("");
    setError("");
    onClose();
  };

  const closeModal = () =>{
    onClose();

  }

  // if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-75 flex items-center justify-center">
      <ClickAwayListener onClickAway={closeModal}>
      <div className="bg-white w-full max-w-md rounded-lg shadow-lg p-6">
        <h2 className="text-xl font-bold mb-4  text-black">Leave a Review</h2>
        <div>
          {/* Star Rating */}
          <label className="block font-medium mb-2 text-black">Rating:</label>
          <div className="flex space-x-2 mb-4">
            {[1, 2, 3, 4, 5].map((stars) => (
              <button
                key={stars}
                type="button"
                onClick={() => setRating(stars)}
                className={`text-2xl ${
                  star >= stars ? "text-yellow-500" : "text-gray-400"
                }`}
              >
                ★
              </button>
            ))}
          </div>

          

          {/* Message Input */}
          <label className="block font-medium mb-2 text-black">Message:</label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="4"
            placeholder="Write your review..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
         {/* Display Error */}
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {/* Action Buttons */}
          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              {isAddReview ? "Submitting" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </ClickAwayListener>
    </div>
  );
};

export default ReviewModal;
