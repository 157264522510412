// EventContext.js

import React, { createContext, useContext, useEffect, useState } from "react";
import ApiTemplate from "../apis/api_template";
import { jsonHeader } from "../apis/header";
import { errorToast, successToast } from "../utils/utilityFunctions";

const EventContext = createContext();

export const EventContextProvider = ({ children }) => {
  const [allEvents, setAllEvents] = useState(null);
  const [allRetreats, setAllRetreats] = useState(null);
  const [allFavoriteEvents, setAllFavoriteEvents] = useState([]);
  const [event, setEvent] = useState(null);
  const [addEventId, setAddEventId] = useState(null);
  const [addRemoveEvent,setAddRemoveEvent] = useState(false);
  const [aboutUsEvents, setAboutUsEvents] = useState(null);
  const [blogsEvents, setBlogsEvents] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);  
    const [pageCount, setPageCount] = useState(0);

  const getEvents = async (page = currentPage, itemPerPage = limit) => {
    try {
      const headers = jsonHeader();

      const response = await ApiTemplate("get", `/api/v1/events?page=${page}&limit=${itemPerPage}`, {}, headers);
      if (response && response["success"] === true) {
        setAllEvents(response["data"]["events"].data);
        setAllEvents(response["data"]["events"] ? response["data"]["events"].data : response.data);
        response["data"]["events"] && setPageCount(response["data"]["events"].last_page);
        response["data"]["events"] && setLimit(response["data"]["events"].per_page);
        response["data"]["events"] && setCurrentPage(response["data"]["events"].current_page)
        setAboutUsEvents(response["data"]);
        setBlogsEvents(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  

  const getEventsByLocation = async (data) => {
    try {
      const headers = jsonHeader();

      const response = await ApiTemplate("post", "/api/v1/event/by/location", data, headers);
      if (response && response["success"] === true) {
        console.log("Using Location : ",response["data"]);
        setAllEvents(response["data"]["events"] ? response["data"]["events"].data : response.data);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getEventsByFilterQuestions = async (queryParams) => {
    try {
      const headers = jsonHeader();
      const response = await ApiTemplate("get", `/api/v1/event/by/filter?${queryParams}`, {}, headers);
      if (response && response["success"] === true) {
        setAllEvents(response["data"]["events"] ? response["data"]["events"].data : response.data);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getEventById = async (id) => {
    try {
      const tok=localStorage.getItem('token');
      const parseTok=JSON.parse(tok);
      const headers = jsonHeader(parseTok);
      // const headers = jsonHeader();

      const response = await ApiTemplate("get", "/api/v1/event/"+id, {}, headers);
      if (response && response["success"] === true) {
        setEvent(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addEvent = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data;",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event", eventData, headers);
      if (response && response["success"] === true) {
        setAddEventId(response["data"]["id"])
        getEvents(token) 
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addExtraImages = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "multipart/form-data;",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/extra/images", eventData, headers);
      if (response && response["success"] === true) {
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventAccomodation = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/accomodation", eventData, headers);
      if (response && response["success"] === true) {
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventFeatures = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/features", eventData, headers);
      if (response && response["success"] === true) {
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const addEventPlans = async (token, eventData) => {
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };

      const response = await ApiTemplate("post", "/api/v1/event/plan", eventData, headers);
      if (response && response["success"] === true) {
        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      return false;

    }
  };

  const deleteEvent = async (token, eventId) => {
    try {
      // Implement the logic to delete an event using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const updateEvent = async (token, eventId, eventData) => {
    try {
      // Implement the logic to update an event using your API here
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const getAllRetreats = async (token) => {
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", "/api/v1/all/retreats", {}, headers);
      if (response && response["success"] === true) {
        setAllRetreats(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };


  const addToFavorite = async (id) => {
    const token=JSON.parse(localStorage.getItem('token'));
    const payload= {event_id:id};
    setAddRemoveEvent(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };


      const response = await ApiTemplate("post", "/api/v1/saveFavorite", payload, headers);
      if (response && response["success"] === true) {
        let favoriteEvents = JSON.parse(localStorage.getItem('favorite')) || [];
      
      // Add the new ID if it’s not already in the array
      if (!favoriteEvents.includes(id)) {
        favoriteEvents.push(id);
        
        // Save the updated array back to localStorage
        localStorage.setItem('favorite', JSON.stringify(favoriteEvents));
      }
      await getEvents();
    setAddRemoveEvent(false);
    successToast("Add to favorite successfully");
      return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      setAddRemoveEvent(false);
      errorToast("No added to favorite")

      return false;

    }
  };

  const removeFromFavorite = async (id) => {
    const token=JSON.parse(localStorage.getItem('token'));
    const payload= {event_id:id};
    setAddRemoveEvent(true);
    try {
      const headers = {
        "Content-Type": "application/json",
        "X-CSRF-TOKEN": document.head
          .querySelector('meta[name="csrf-token"]')
          ?.getAttribute("content"),
        Authorization: `Bearer ${token}`,
      };


      const response = await ApiTemplate("post", "/api/v1/removeFavorite", payload, headers);
      if (response && response["success"] === true) {
        let favoriteEvents = JSON.parse(localStorage.getItem('favorite')) || [];
       // Filter out the specific id to remove it from the array
       favoriteEvents = favoriteEvents.filter(eventId => eventId !== id);

       // Save the updated array back to localStorage
       localStorage.setItem('favorite', JSON.stringify(favoriteEvents));
      await getEvents();
      await getAllFavorite();
      setAddRemoveEvent(false);
      successToast('Romoved from favorite successfully')

        return true;
      } else {
        console.log(response["message"], response.message);
        return false;

      }
    } catch (error) {
      console.error("Error during API call:", error);
      setAddRemoveEvent(false);
      errorToast("Not removed! try again")
      return false;

    }
  };

  const getAllFavorite = async () => {
    const token = JSON.parse(localStorage.getItem('token'));
    try {
      const headers = jsonHeader(token);

      const response = await ApiTemplate("get", "/api/v1/getFavorite", {}, headers);
      if (response && response["success"] === true) {
        setAllFavoriteEvents(response["data"]);
      } else {
        console.log(response["message"], response.message);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

 useEffect(()=>{
  getEvents(currentPage)
 },[currentPage])


  return (
    <EventContext.Provider
      value={{
        allEvents,
        getEvents,
        addEvent,
        addExtraImages,
        deleteEvent,
        updateEvent, getEventById, event, getEventsByLocation,
        addEventId, setAddEventId, addEventAccomodation, addEventFeatures, addEventPlans,
        allRetreats, getAllRetreats,
        getEventsByFilterQuestions,
        addToFavorite,
        getAllFavorite,
        allFavoriteEvents,
        removeFromFavorite,
        addRemoveEvent,
        aboutUsEvents,
        blogsEvents,
        currentPage,
        setCurrentPage,
        pageCount,
        limit,
        setLimit,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export const useEventContext = () => {
  const eventContextValue = useContext(EventContext);
  return eventContextValue;
};
